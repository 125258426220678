import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Status from './Status';
import Main from './Component/Main';
import { CircleLoader } from 'react-spinners';
import { ErrorBoundary } from './ErrorBoundary';
import NotFound from './helper/errorPages/NotFoundPage';
import ServerErrorPage from './ErrorPage';
import ScholarshipClosed from './helper/ScholarshipClosed';
import VerticalStepperForMultipleYearRequest from './Multipleyearrequest/Stepper';
const App = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<CircleLoader />}>
              <ErrorBoundary fallback={<ServerErrorPage />}>
                <Main />
              </ErrorBoundary>
            </React.Suspense>
          }
        />
        <Route
          path="/status"
          element={
            <React.Suspense fallback={<CircleLoader />}>
              <ErrorBoundary fallback={<ServerErrorPage />}>
                <Status />
              </ErrorBoundary>
            </React.Suspense>
          }
        />
        <Route
          path="/form"
          element={
            <React.Suspense fallback={<CircleLoader />}>
              <ErrorBoundary fallback={<ServerErrorPage />}>
                <VerticalStepperForMultipleYearRequest />
              </ErrorBoundary>
            </React.Suspense>
          }
        />
        <Route
          path="/closed"
          element={
            <React.Suspense fallback={<CircleLoader />}>
              <ErrorBoundary fallback={<ServerErrorPage />}>
                <ScholarshipClosed />
              </ErrorBoundary>
            </React.Suspense>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
