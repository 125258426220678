import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import Item from '@mui/material/Grid';
import { Box, Typography, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CustomAmountInput from '../../helper/Form/Amount';
import { Button } from '@mui/material';

const FILE_SIZE = 500 * 1024;

const schema = yup.object().shape({
  marksheetsFile: yup
    .mixed()
    .test('fileRequired', 'Marksheet is required', function (value) {
      return value && !!value[0]; // Check if value is defined before accessing index 0
    })
    .test('fileSize', 'File size should be less than 500KB', (value) => {
      if (value) {
        const file = value[0];
        return file && file.size <= FILE_SIZE;
      }
    }),
  bonafideCertificate: yup
    .mixed()
    .test('fileRequired', 'bonafideCertificate is required', function (value) {
      return value && !!value[0]; // Check if value is defined before accessing index 0
    })
    .test('fileSize', 'File size should be less than 500KB', (value) => {
      if (value) {
        const file = value[0];
        return file && file.size <= FILE_SIZE;
      }
    }),
  MSEDLetter: yup
    .mixed()
    .test(
      'fileRequired',
      'MSET scholarship form is required',
      function (value) {
        return value && !!value[0]; // Check if value is defined before accessing index 0
      }
    )
    .test('fileSize', 'File size should be less than 500KB', (value) => {
      if (value) {
        const file = value[0];
        return file && file.size <= FILE_SIZE;
      }
    }),
  accountNumber: yup
    .string()
    // .typeError("Account Number must be a number")
    .matches(/^\d*$/, 'Account Number must be alphabets') // Allow only numbers
    .max(25, 'Account Number cannot exceed 25 digits') // Limit to 25 digits
    .required('Account Number is required'), // Field is required

  ifscCode: yup
    .string()
    .required('IFSC Code is required')
    .matches(
      /^[A-Za-z0-9]+$/,
      'IFSC Code must contain only alphanumeric characters'
    )
    .matches(/^[^\s]+$/, 'IFSC Code cannot contain spaces'),

  bankName: yup
    .string()
    .required('Bank Name is required')
    .max(50, 'Bank Name must be at most 50 characters')
    .matches(/^[a-zA-Z\s]*$/, 'Enter the correct Bank Name'),
  branchName: yup
    .string()
    .required('Branch Name is required')
    .max(30, 'Branch Name must be at most 30 characters')
    .matches(/^[a-zA-Z\s]*$/, 'Enter the correct Branch Name'),
  paymentAddress: yup.string().required('Payment Address is required'),
  amountRequested: yup
    .number()
    .typeError('Aid amount must be a number')
    .positive('Aid amount must be greater than zero')
    .min(1, 'Aid amount must be greater than zero')
    .required('Aid Amount Requested is required'),
});

function CertificateAndBankDetails({
  formData,
  setFormData,
  handleNext,
  files,
  fileSelected,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formData,
  });

  const onSubmit = (data) => {
    console.log('Form Data:', data);
    setFormData(data);
    handleNext();
  };

  return (
    <Box>
      <Grid
        container
        spacing={2}
        p={2}
        sx={{
          display: 'flex',
        }}
      >
        <Grid item md={6} sm={12}>
          <Typography
            sx={{ mb: 0.5 }}
            className="fs-6 text-dark-emphasis fw-normal"
          >
            Upload Marksheet <span style={{ color: 'orangered' }}>*</span>
          </Typography>
          <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
            File size should be less than 500KB
          </Typography>
          <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
            Only JPG, PNG, and PDF files are allowed
          </Typography>
          <div className="input-group">
            <label
              htmlFor="inputGroupFileMarkSheet"
              className="form-control border-primary"
            >
              {files[0] ? files[0].name : 'Choose a file'}
            </label>
            <Controller
              name="marksheetsFile"
              control={control}
              render={({ field }) => (
                <input
                  type="file"
                  className="visually-hidden"
                  id="inputGroupFileMarkSheet"
                  aria-describedby="inputGroupFileAddonMarkSheet"
                  aria-label="Upload"
                  onChange={(e) => {
                    field.onChange(e.target.files);
                    fileSelected(0, e);
                  }}
                  required
                />
              )}
            />
            <button
              className="btn btn-outline-primary"
              type="button"
              id="inputGroupFileAddonMarkSheet"
              onClick={() => {
                document.getElementById('inputGroupFileMarkSheet').click();
              }}
            >
              <CloudUploadIcon />
            </button>
          </div>
          {errors.marksheetsFile && (
            <Typography variant="caption" color="error">
              {errors.marksheetsFile.message}
            </Typography>
          )}
        </Grid>
        <Grid item md={6} sm={12}>
          <>
            <Typography
              sx={{ mb: 0.5 }}
              className="fs-6 text-dark-emphasis fw-normal"
            >
              Upload Fee Details in College Letter Head issued by the College{' '}
              <span style={{ color: 'orangered' }}>*</span>
            </Typography>
            <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
              File size should be less than 500KB
            </Typography>
            <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
              Only JPG, PNG, and PDF files are allowed
            </Typography>
            <div className="input-group">
              <label
                htmlFor="inputGroupFileBonafideCertificate"
                className="form-control border-primary"
              >
                {files[1] ? files[1].name : 'Choose a file'}
              </label>
              <Controller
                name="bonafideCertificate"
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    className="form-control border-primary visually-hidden"
                    id="inputGroupFileBonafideCertificate"
                    aria-describedby="inputGroupFileAddonBonafideCertificate"
                    aria-label="Upload"
                    onChange={(e) => {
                      field.onChange(e.target.files);
                      fileSelected(1, e);
                    }}
                    required
                  />
                )}
              />
              <button
                className="btn btn-outline-primary"
                type="button"
                id="inputGroupFileAddonBonafideCertificate"
                onClick={() => {
                  document
                    .getElementById('inputGroupFileBonafideCertificate')
                    .click();
                }}
              >
                <CloudUploadIcon />
              </button>
            </div>
          </>
          {errors.bonafideCertificate && (
            <Typography variant="caption" color="error">
              {errors.bonafideCertificate.message}
            </Typography>
          )}
        </Grid>
        <Grid item md={6} sm={12}>
          <Typography
            gutterBottom
            component="div"
            className="fw-semibold text-primary"
          >
            Attachments
          </Typography>
          <>
            <Typography
              sx={{ mb: 0.5 }}
              className="fs-6 text-dark-emphasis fw-normal"
            >
              MSET Scholarship Letter{' '}
              <span style={{ color: 'orangered' }}>*</span>
            </Typography>
            <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
              File size should be less than 500KB
            </Typography>
            <Typography sx={{ mb: 1, fontSize: '12px', color: 'blue' }}>
              Only JPG, PNG, and PDF files are allowed
            </Typography>
            <div className="input-group">
              <label
                htmlFor="inputGroupFileReferenceLetter"
                className="form-control border-primary"
              >
                {files[2] ? files[2].name : 'Choose a file'}
              </label>
              <Controller
                name="MSEDLetter"
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    className="form-control border-primary visually-hidden"
                    id="inputGroupFileReferenceLetter"
                    aria-describedby="inputGroupFileAddonReferenceLetter"
                    aria-label="Upload"
                    onChange={(e) => {
                      field.onChange(e.target.files);
                      fileSelected(2, e);
                    }}
                    required
                  />
                )}
              />
              <button
                className="btn btn-outline-primary"
                type="button"
                id="inputGroupFileAddonReferenceLetter"
                onClick={() => {
                  document
                    .getElementById('inputGroupFileReferenceLetter')
                    .click();
                }}
              >
                <CloudUploadIcon />
              </button>
            </div>
            {/* Display file selection error */}
            {errors.MSEDLetter && (
              <Typography variant="caption" color="error">
                {errors.MSEDLetter.message}
              </Typography>
            )}
          </>
        </Grid>
        <Grid item md={6} sm={12}></Grid>
        <Grid item md={12} sm={12}>
          <Typography sx={{ fontWeight: 'bold', my: 1 }}>
            Please enter the bank details of the College. NOT your personal bank
            details
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Controller
            name="accountNumber"
            control={control} // Pass the control prop from useForm()
            defaultValue="" // Set default value if needed
            render={({ field }) => (
              <TextField
                {...field} // Spread field props into the TextField component
                id="outlined-textarea"
                label={
                  <label>
                    Account Number
                    <span style={{ color: 'orangered' }}> *</span>
                  </label>
                }
                placeholder="Enter Account Number"
                size="small"
                className="responsive-form"
                error={!!errors.accountNumber}
                helperText={
                  errors.accountNumber ? errors.accountNumber.message : ''
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <Controller
            name="ifscCode" // Specify the name of the field
            control={control} // Pass the control prop from useForm()
            defaultValue="" // Set default value if needed
            render={({ field }) => (
              <Item>
                <TextField
                  {...field} // Spread field props into the TextField component
                  id="outlined-textarea"
                  label={
                    <label>
                      IFSC Code
                      <span style={{ color: 'orangered' }}> *</span>
                    </label>
                  }
                  placeholder="Enter IFSC Code"
                  size="small"
                  className="responsive-form"
                  error={!!errors.ifscCode}
                  helperText={errors.ifscCode ? errors.ifscCode.message : ''}
                  fullWidth
                />
              </Item>
            )}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <Controller
            name="bankName" // Specify the name of the field
            control={control} // Pass the control prop from useForm()
            defaultValue="" // Set default value if needed
            render={({ field }) => (
              <Item>
                <TextField
                  {...field} // Spread field props into the TextField component
                  id="outlined-textarea"
                  label={
                    <label>
                      Bank Name
                      <span style={{ color: 'orangered' }}> *</span>
                    </label>
                  }
                  placeholder="Enter Bank Name"
                  size="small"
                  className="responsive-form"
                  error={!!errors.bankName} // Convert error to boolean
                  helperText={errors.bankName ? errors.bankName.message : ''}
                  fullWidth // Display error message
                />
              </Item>
            )}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <Controller
            name="branchName" // Specify the name of the field
            control={control} // Pass the control prop from useForm()
            defaultValue={''} // Set default value if needed
            render={({ field }) => (
              <Item>
                <TextField
                  {...field} // Spread field props into the TextField component
                  id="outlined-textarea"
                  label={
                    <label>
                      Branch Name
                      <span style={{ color: 'orangered' }}> *</span>
                    </label>
                  }
                  placeholder="Enter Branch Name"
                  size="small"
                  className="responsive-form"
                  error={!!errors.branchName} // Convert error to boolean
                  helperText={
                    errors.branchName ? errors.branchName.message : ''
                  } // Display error message
                  fullWidth
                />
              </Item>
            )}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <Controller
            name="paymentAddress" // Specify the name of the field
            control={control} // Pass the control prop from useForm()
            defaultValue={''} // Set default value if needed
            render={({ field }) => (
              <Item>
                <TextField
                  {...field} // Spread field props into the TextField component
                  id="outlined-textarea"
                  label={
                    <label>
                      Branch Address
                      <span style={{ color: 'orangered' }}> *</span>
                    </label>
                  }
                  placeholder="Enter Payment Address"
                  size="small"
                  className="responsive-form"
                  fullWidth
                  error={!!errors.paymentAddress} // Convert error to boolean
                  helperText={
                    errors.paymentAddress ? errors.paymentAddress.message : ''
                  } // Display error message
                />
              </Item>
            )}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <CustomAmountInput
            label="Aid Amount Requested *"
            name="amountRequested"
            placeholder="Enter Amount"
            control={control}
            error={errors.amountRequested}
            className="amountInput"
          />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex' }}>
        {/* <Button variant="outlined" onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </Button> */}
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          sx={{ mt: 1, mr: 1 }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
}

export default CertificateAndBankDetails;
