import React, { useState } from 'react';
// import Review from './Stepper/review';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Logo from '../assets/idea-logo.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../AidRequest/Stepper/form.css';

import axios from 'axios';
import Alerts from '../helper/Alert';
import SuccessDialog from '../helper/SuccessDialog';
import CertificateAndBankDetails from './form/step1';
import Acknowledgement from './form/step2';
import { useLocation } from 'react-router-dom';
import ReviewMultiYearRequestData from './Review';

export default function VerticalStepperForMultipleYearRequest() {
  const location = useLocation();
  const document = location.state?.document;
  console.log('DOCUMENT', document);
  const [loading, setLoading] = useState(false);
  const [applicantId, setApplicantId] = useState('');
  const [fileFolderName, setFileFolderName] = useState('');

  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
    severity: '',
  });

  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    //step1
    marksheetsFile: '',
    bonafideCertificate: '',
    MSEDLetter: '',
    amountRequested: null,
    accountNumber: '',
    ifscCode: '',
    bankName: '',
    branchName: '',
    paymentAddress: '',
  });
  const [files, setFiles] = useState([null, null, null]);
  const fileSelected = (index, event) => {
    const file = event.target.files[0];
    const updatedFiles = [...files];
    updatedFiles[index] = file;
    setFiles(updatedFiles);
  };
  const API_URL = process.env.React_App_Api;
  const postForm = async () => {
    try {
      setLoading(true);
      let dataobj = [];

      const fileData = new FormData();
      for (let i = 0; i < files.length; i++) {
        fileData.append('images', files[i]);
      }
      let folderName = '';
      if (
        document.applicant.firstName &&
        document.applicant.lastName &&
        document.applicant.aadharcardnumber
      ) {
        folderName = `${document.applicant.firstName}${
          document.applicant.lastName
        }${document.applicant.aadharcardnumber.slice(-4)}`;
      } else {
        folderName = fileFolderName;
      }
      fileData.append('folderName', folderName);
      if (files.length > 0) {
        await axios
          .post(`${API_URL}/file/posts`, fileData)
          .then((res) => {
            dataobj = res.data.files;
            // dataobj.filesPath = uploadedUrls;
          })
          .catch((error) => {
            if (error.response) {
              switch (error.response.status) {
                case 400:
                  throw new Error(
                    'No files uploaded or invalid data. Please try again.'
                  );
                case 500:
                  throw new Error(
                    'Server error occurred while uploading files.'
                  );
                default:
                  throw new Error(
                    error.response.data || 'Unexpected error occurred.'
                  );
              }
            } else if (error.message) {
              throw new Error(`File upload failed: ${error.message}`);
            } else {
              throw new Error('An unknown error occurred during file upload.');
            }
          });
      }
      const data = {
        address: document.address,
        applicant: {
          aadharCardBack: document.applicant.aadharCardBack,
          aadharCardFront: document.applicant.aadharCardFront,
          aadharcardnumber: document.applicant.aadharcardnumber,
          appemail: document.applicant.appemail,
          dob: document.applicant.dob,
          firstName: document.applicant.firstName,
          lastName: document.applicant.lastName,
          marksheetsFile: dataobj[0],
          passportSizePhotoFile: document.applicant.passportSizePhotoFile,
          phonenumber: document.applicant.phonenumber,
        },
        aidinformation: {
          amtrequested: formData.amountRequested,
          reasonforrequesting: document.aidinformation.reasonForRequesting,
          aidtype: document.aidinformation.aidtype,
          multipleyears: document.aidinformation.multipleyears,
          yearsofsupportisneeded:
            document.aidinformation.yearsofsupportisneeded,
        },
        committeenotes: '',
        comments: '',
        financecommitteenotes: 'string',
        indiavolunteer: document.indiavolunteer,
        institutiondetails: {
          name: document.institutiondetails.name,
          mobileNumber: document.institutiondetails.mobileNumber,
          email: document.institutiondetails.email,
          address: document.institutiondetails.address,
          city: document.institutiondetails.city,
          state: document.institutiondetails.state,
          district: document.institutiondetails.district,
          pincode: document.institutiondetails.pincode,
          website: document.institutiondetails.webiste,
          bonafideCertificate: dataobj[1],
        },
        reviewcomments: 'string',
        username: 'string',
        submittername: 'string',
        submitterphonenumber: formData.submitterphonenumber,
        status: 'new',
        questions: {
          answer: 'string',
        },
        parentorguardian: document.parentorguardian,
        paymentmethod: {
          ifsccode: formData.ifscCode,
          accountnumber: formData.accountNumber,
          bankname: formData.bankName,
          branchname: formData.branchName,
          branchAddress: formData.paymentAddress,
          beneficiaryname: document.paymentmethod.beneficiaryname,
          beneficiaryphone: document.paymentmethod.beneficiaryphone,
        },
        usavolunteer: document.usavolunteer,
        msetScholarshipLetter: dataobj[2],
        multipleYearRequest: true,
      };
      //   if (formData.multipleyears === 'yes') {
      //     data.aidinformation.yearsofsupportisneeded =
      //       formData.yearsofsupportisneeded;
      //   }
      console.log('DATA', data);
      await axios
        .post(`${API_URL}/aid/insert`, data)
        .then((res) => {
          setOpen(true);
        })
        .catch((error) => {
          console.log('ERROR', error);
          if (error.response) {
            switch (error.response.status) {
              case 409:
                throw new Error(error.response.data.error);
              case 503:
                throw new Error('Service unavailable. Please try again later.');
              default:
                throw new Error(
                  error.response.data || 'Unexpected error occurred.'
                );
            }
          } else if (error.message) {
            throw new Error(`Request failed: ${error.message}`);
          } else {
            throw new Error(
              'An unknown error occurred while submitting the form.'
            );
          }
        });
    } catch (error) {
      console.log('ERROR', error);
      setAlert({
        open: true,
        message: error.message || 'An unexpected error occurred.',
        severity: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    } finally {
      setLoading(false); // Set loading state to false when form submission completes (whether success or error)
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    console.log(activeStep);
    console.log(formData);
    console.log(errors);
    return setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const steps = [
    {
      label: 'Certificate and bank details',
      description: (
        <CertificateAndBankDetails
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          handleNext={handleNext}
          handleBack={handleBack}
          files={files}
          fileSelected={fileSelected}
        />
      ),
    },
    {
      label: 'Acknowledgement & Declaration',
      description: (
        <Acknowledgement handleNext={handleNext} handleBack={handleBack} />
      ),
    },
    {
      label: 'Review',
      description: (
        <ReviewMultiYearRequestData
          loading={loading}
          formData={document}
          setFormData={setFormData}
          handleBack={handleBack}
        />
      ),
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  // let applicantId = '';

  React.useEffect(() => {
    const myData = localStorage.getItem('myUsaDatastep1');
    const volunteersDetail = localStorage.getItem('volunteersDetail');
    if (myData) {
      const storedDataString = localStorage.getItem('myUsaDatastep1');
      const storedData = JSON.parse(storedDataString);
      const folderName = `${storedData.step1.firstName}${
        storedData.step1.lastName
      }${storedData.step1.phoneNumber.slice(-4)}`;
      setFileFolderName(folderName);
      setApplicantId(storedData.step1.id);
      setActiveStep(3); // Assuming Step1 is completed
    } else if (volunteersDetail) {
      setActiveStep(2);
    } else {
      setActiveStep(0); // Start from the first step
    }
  }, []);

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: '',
      severity: '',
    });
  };
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <Box sx={{ p: 2, md: '' }}>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
      />
      <SuccessDialog open={open} setOpen={setOpen} multipleYearRequest={true} />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography sx={{ fontSize: '23px', fontWeight: 'bold' }}>
          <img
            src={Logo}
            alt="logo"
            width={'37'}
            height={'37'}
            // style={{ marginRight: "40px" }}
          />{' '}
          IDEA Scholarship Application - {currentYear}{' '}
          <img
            src={Logo}
            alt="logo"
            width={'37'}
            height={'37'}
            style={{ marginRight: '40px' }}
          />
        </Typography>
      </Box>

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 1 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {step.description}
              <Box sx={{ mb: 2 }}>
                {index === 2 ? (
                  <Box sx={{ display: 'flex' }}>
                    <Button
                      variant="outlined"
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>{' '}
                    <Button
                      variant="contained"
                      color="success"
                      // onClick={modelBox}
                      onClick={postForm}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Review and Submit
                    </Button>
                  </Box>
                ) : (
                  ''
                )}
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
